import { useState } from "react";
import { Grid, Card, Heading, Text, Button, Loader } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';

export default function HomingRegister(props) {
  const [showLoading, setShowLoading] = useState(false);
  const [showNext, setShowNext] = useState(false);

  async function homingRegister() {
    setShowLoading(true);
    API.post('apie9a86f09', `/api/${props.instance}/homing/register`, { body: {
      customerDeviceId: props.vehicleInfo.customerDeviceId,
      customerId: props.vehicleInfo.customerId,
      requestId: '72da7075-4c56-43f2-afa0-5e29dbc5f89e'
    }})
    .then((data) => {
      console.log(data);
      setShowNext(true);
      setShowLoading(false);
    });
  }

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 2fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        {showLoading && <Loader variation="linear" />}
        {showNext && (
          <div>
            <Text style={{marginBottom: "1em"}}>Please follow the activation sequence again.</Text>
            <Button
              variation="primary"
              style={{marginTop: "1em"}}
              onClick={() => props.onNext()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='homingActivateNext'
            >Next</Button>
            <Button
              variation="secondary"
              style={{marginLeft: "0.5em"}}
              onClick={() => props.onBack()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='homingActivateBack'
            >Back</Button>
          </div>
        )}
        {!showNext && !showLoading && (
          <div>
            <Heading level={4} style={{marginBottom: "0.5em"}}>Warning</Heading>
            <Text variation="error" style={{marginBottom: "0.5em"}}>The device is likely in an orphaned state!</Text>
            <Text style={{marginBottom: "0.5em"}}>This means the unit is somewhere other than {props.instance} and is unable to activate. The system can attempt to discover where the unit is and relocate it.</Text>
            <Text style={{marginBottom: "0.5em"}}>Please note this procedure may have unintended consequences in other instances, by clicking "Confirm" you accept this will not impact customers.</Text>
            <Button
              variation="primary"
              style={{marginTop: "1em"}}
              onClick={homingRegister}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='homingRegisterNext'
            >Confirm</Button>
            <Button
              variation="secondary"
              style={{marginLeft: "0.5em"}}
              onClick={() => props.onBack()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='homingRegisterBack'
            >Back</Button>
          </div>
        )}
      </Card>
    </Grid>
  )
}