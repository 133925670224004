import { Authenticator, Grid, Card } from '@aws-amplify/ui-react';
import { Amplify, Analytics, Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import FlowHandler from './FlowHandler';
import Header from './components/Header';
import UnitContext, { conversions } from './utils/UnitContext.ts';

import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';

Amplify.configure({
  ...awsExports,
  oauth: {
    "domain": "installer-toolkit.auth.us-east-1.amazoncognito.com",
    "scope": [
      "profile",
      "openid",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "https://toolkit.denso.cloud",
    "redirectSignOut": "https://toolkit.denso.cloud",
    "responseType": "code"
  }
});

Analytics.autoTrack('session', {
  enable: true
});

Analytics.autoTrack('event', {
  enable: true
});

export default function App() {
  const [unit, setUnit] = useState('metric');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log(code);

    const signIn = async () => {
      await Auth.federatedSignIn('identity-center', { code });
    }

    if (code) {
      signIn();
    }
  }, []);

  const changeUnit = async () => {
    setUnit(unit === 'metric' ? 'imperial' : 'metric');
  }

  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) => (
        <main>
          <Grid
            columnGap="0.5rem"
            rowGap="0.5rem"
          >
            <Card>
              <Header user={user} unit={conversions[unit].display} signOut={signOut} changeUnit={changeUnit} />
            </Card>
            <Card>
              <UnitContext.Provider value={conversions[unit]}>
                <FlowHandler user={user} />
              </UnitContext.Provider>
            </Card>
          </Grid>
        </main>
      )}
    </Authenticator>
  );
}