import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Grid, Card, Loader, Heading, Text, Button, Flex } from '@aws-amplify/ui-react';

export default function ActiveInstances(props) {
  const [showLoading, setShowLoading] = useState(true);
  const [activeInstances, setActiveInstances] = useState({ asset: {}, device: {} });

  useEffect(() => {
    API.post('apie9a86f09', `/api/${props.instance}/discover`, { body: {
      assetId: props.assetId,
      deviceId: props.deviceId
    }})
    .then((data) => {
      if (Object.keys(data.asset).every((i) => data.asset[i] === null) && Object.keys(data.device).every((i) => data.device[i] === null)) {
        props.onNext();
      } else {
        const rawData = new Set([
          ...Object.keys(data.asset).filter((i) => props.instances.includes(i) && data.asset[i] !== null).map((i) => `${i}|${data.asset[i].customerDeviceId}|${data.asset[i].deviceId}`),
          ...Object.keys(data.device).filter((i) => props.instances.includes(i) && data.device[i] !== null).map((i) => `${i}|${data.device[i].customerDeviceId}|${data.device[i].deviceId}`)
        ]);
        const filtered = Array.from(rawData).map((v) => ({ instance: v.split('|')[0], assetId: v.split('|')[1], deviceId: v.split('|')[2] }));
        if (filtered.length === 1) {
          console.log(filtered[0]);
          props.onSubmit(filtered[0]);
        }
        setActiveInstances(filtered);
        setShowLoading(false);
      }
    })
  }, []);

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 2fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        {showLoading && <Loader variation="linear" />}
        {!showLoading && (
          <div>
            <Heading level={4} style={{marginBottom: "0.5em"}}>Active Instances</Heading>
            {activeInstances.map((i, idx) => (
              <Card key={idx}>
                <Flex direction="column">
                  <Heading level={5}>
                    {i.instance}
                  </Heading>
                  <Text>{i.assetId} paired with {i.deviceId}</Text>
                  <Button
                    variation="primary"
                    onClick={() => props.onSubmit(i)}
                    data-amplify-analytics-on='click'
                    data-amplify-analytics-name='activeInstancesView'
                  >View</Button>
                </Flex>
              </Card>
            ))}
            <Button
              variation="primary"
              style={{marginTop: "1em"}}
              onClick={() => props.onNext()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='activeInstancesNext'
            >Skip</Button>
            <Button
              variation="secondary"
              style={{marginLeft: "0.5em"}}
              onClick={() => props.onBack()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='activeInstancesBack'
            >Back</Button>
          </div>
        )}
      </Card>
    </Grid>
  )
}