/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:eb4ac8dd-fdd8-4896-8944-1bdfba55d5dd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QE0b07Bi4",
    "aws_user_pools_web_client_id": "2ij5ks1aml3ao1p3f6bt3nf3vl",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "apie9a86f09",
            "endpoint": "https://nydyao6jji.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "8d4689ddf67249ecaa2589c9a28dfabd",
    "aws_mobile_analytics_app_region": "us-east-1",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "map53469462-staging": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "map53469462-staging"
            }
        }
    }
};


export default awsmobile;
