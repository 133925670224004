import { Flex, Text } from '@aws-amplify/ui-react';
import { Check, X } from 'tabler-icons-react';

export default function ResultItem(props) {
  return (
    <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
      <Text>{props.name}</Text>
      {props.result === 'pass' && <Check color='green' size={24} />}
      {props.result === 'fail' && <X color='red' size={24} />}
    </Flex>
  )
}
