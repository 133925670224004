import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Grid, Card, TextField, SelectField, Button } from '@aws-amplify/ui-react';
import VinDecoder from 'vin-decoder';


export default function CollectRegisterInfo(props) {
  const vehicleInfo = VinDecoder.getVinInfo(props.assetId);
  const [make, setMake] = useState(vehicleInfo.make || '');
  const [model, setModel] = useState(vehicleInfo.model || '');
  const [modelYear, setModelYear] = useState(vehicleInfo.year || '');
  const [instance, setInstance] = useState(props.instances[0]);
  const [customerId, setCustomerId] = useState(props.customers[0]);
  const [fleetId, setFleetId] = useState();
  const [fleetOptions, setFleetOptions] = useState(['Loading...']);

  function handleSubmit() {
    props.onSubmit({
      vehicleInfo: {
        customerId,
        fleetId,
        customerDeviceId: props.assetId,
        requestId: '69fe1b6c-3e47-41e2-9b78-8345bcb83773',
        ...(make && {
          make
        }),
        ...(model && {
          model
        }),
        ...(modelYear && {
          modelYear: parseInt(modelYear, 10)
        })
      },
      instance,
    });
  }

  async function fetchFleets() {
    setFleetOptions(['Loading...']);
    const customer = await API.get('apie9a86f09', `/api/${instance}/customers/${customerId}`);
    const fleets = Object.keys(customer.fleets);
    setFleetOptions(fleets)
    setFleetId(fleets[0])
  }

  useEffect(() => {
    fetchFleets();
  },[instance, customerId]);

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 1fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <TextField
          label="Make"
          placeholder="Toyota"
          descriptiveText={vehicleInfo.make ? `Automatically detected "${vehicleInfo.make}"` : ""}
          value={make}
          onChange={(e) => setMake(e.target.value)}
          style={{marginBottom: "1em"}}
        />
        <TextField
          label="Model"
          placeholder="Prius"
          descriptiveText={vehicleInfo.model ? `Automatically detected "${vehicleInfo.model}"` : ""}
          value={model}
          onChange={(e) => setModel(e.target.value)}
          style={{marginBottom: "1em"}}
        />
        <TextField
          label="Year"
          placeholder="2022"
          descriptiveText={vehicleInfo.year ? `Automatically detected "${vehicleInfo.year}"` : ""}
          value={modelYear}
          onChange={(e) => setModelYear(e.target.value)}
          style={{marginBottom: "1em"}}
        />
        <SelectField
          label="Instance"
          options={props.instances}
          onChange={(e) => setInstance(e.target.value)}
          style={{marginBottom: "1em"}}
        ></SelectField>
        <SelectField
          label="Customer"
          options={props.customers}
          onChange={(e) => setCustomerId(e.target.value)}
          style={{marginBottom: "1em"}}
        ></SelectField>
        <SelectField
          label="Fleet"
          options={fleetOptions}
          onChange={(e) => setFleetId(e.target.value)}
          style={{marginBottom: "1em"}}
        ></SelectField>
        <Button
          variation="primary"
          onClick={handleSubmit}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='registerInfoNext'
        >Next</Button>
        <Button 
          variation="secondary"
          style={{marginLeft: "0.5em"}}
          onClick={() => props.onBack()}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='registerInfoBack'
        >Back</Button>
      </Card>
    </Grid>
  )
}