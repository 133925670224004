import { useEffect, useState } from 'react';
import { Grid, Card, Text, Heading, Button } from '@aws-amplify/ui-react';

export default function BluetoothScan(props) {
  const [results, setResults] = useState([]);

  navigator.bluetooth.addEventListener("advertisementreceived", (event) => {
    setResults((a) => [
      ...a,
      <li>`Device: ${event.name}, RSSI: ${event.rssi}dB Manufacturer data: 0x${event.manufacturerData[0].valueDataView.getUint16().toString(16)}`</li>
    ])
  });

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 1fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <Card
        columnStart="3"
        columnEnd="4"
      >
        <Heading level={3}>Results</Heading>
        <ul>{results}</ul>
      </Card>
        <Button
          variation="secondary"
          style={{marginTop: "1em"}}
          onClick={() => props.onBack()}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='assetInfoNext'
        >Cancel</Button>
      </Card>
    </Grid>
  )
}