import React from "react";
import Icons from "./custom-icons.svg";
import Audi from './audi-sprite.svg'
import PropTypes from 'prop-types';

const CustomIcon = ({ name, color, size }) => (
  <svg className={`icon icon-${name}`} fill={color} color={color} width={size} height={size}>
    <use xlinkHref={`${Icons}#${name}`} />
    <use xlinkHref={`${Audi}#${name}`} />
  </svg>
);

CustomIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number
};

export default CustomIcon;