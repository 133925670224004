import { useState } from "react";
import { Alert } from '@aws-amplify/ui-react';
import CollectAssetInfo from './views/CollectAssetInfo';
import ActiveInstances from "./views/ActiveInstances";
import CollectRegisterInfo from "./views/CollectRegisterInfo";
import AssetDiscovery from "./views/AssetDiscovery";
import SetOptions from "./views/SetOptions";
import SetConfiguration from "./views/SetConfiguration";
import OnboardingTracker from "./views/OnboardingTracker";
import ActivateHelp from "./views/ActivateHelp";
import HomingRegister from "./views/HomingRegister";
import AssetView from "./views/AssetView";
import ValidationFlow from "./ValidationFlow";
import FirmwareUpdate from "./views/FirmwareUpdate";
import AssetDetail from './views/AssetDetail';
import BluetoothScan from "./views/BluetoothScan";
import Dashboard from "./views/Dashboard";
import AdvancedOptions from "./views/AdvancedOptions";

const defaultConfig = {
  stateRefresh: {
    powerOn: 60,
    powerOffReserved: 300,
    powerOff: 1800,
    powerOffCharging: 300
  },
  alert: {
    powerState: true,
    alarm: false,
    speed: 93,
    battery: 11,
    idle: 240
  },
  firmwareUpdateCriteria: {
    powerOff: true,
    notReserved: true,
    downloadOnEdge: false,
    batteryVoltage: 11
  },
  powerActivateTimeout: 120,
  immobilizationMode: 'FULL',
  trackingEnabled: true
};

const setState = (item, obj) => window.localStorage.setItem(`toolkit-${item}`, JSON.stringify(obj));

const getState = (item) => JSON.parse(window.localStorage.getItem(`toolkit-${item}`) || null);

export default function FlowHandler(props) {
  const assetInfo = getState('asset-info');
  const instance = getState('instance');

  const [data, setData] = useState({
    assetInfo: assetInfo ? assetInfo : { assetId: '', deviceId: '' },
    options: { cleanSlate: true, delayCheck: true, sendConfig: true },
    config: defaultConfig,
    instance,
    instances: props.user.attributes["custom:instances"],
    customers: props.user.attributes["custom:customers"]
  });
  const [step, setStep] = useState(assetInfo && instance ? 'assetView' : 'collectAssetInfo');
  const [toasts, setToasts] = useState([]);

  if ((!data.instances || ! data.customers) && step != 'accountLock') {
    setStep('accountLock');
  }

  function pushToast(type, title, message, autoHide=true) {
    setToasts((t) => [...t, {
      type,
      title,
      message
    }]);
    if (autoHide) {
      setTimeout(() => setToasts((t) => t.slice(0,-1)), 10000);
    }
  }

  function collectAssetInfoSubmit(form) {
    setData((p) => ({
      ...p,
      assetInfo: form
    }));
    setState('asset-info', form);
    setStep('activeInstances');
  }

  async function scanBluetooth() {
    setStep('bluetooth');
    await navigator.bluetooth.requestLEScan({
      acceptAllAdvertisements:true
    });
  }

  function activeInstancesSubmit({ instance, ...rest }) {
    setData((p) => ({
      ...p,
      instance,
      assetInfo: rest,
      synthesis: null
    }));
    setState('instance', instance);
    setState('asset-info', rest);
    setStep('assetView');
  }

  function collectRegisterInfoSubmit(form) {
    setData((p) => ({
      ...p,
      ...form,
    }));
    setStep('assetDiscovery');
  }

  function assetDiscoverySubmit(form) {
    setData((p) => ({
      ...p,
      discovery: form,
    }));
    if (data.helpTriggered) {
      setStep('onboardingTracker');
    } else {
      setStep('setOptions');
    }
  }

  function setOptionsSubmit(form) {
    setData((p) => ({
      ...p,
      options: form,
    }));
    if (form.sendConfig) {
      setStep('setConfiguration');
    } else {
      setStep('onboardingTracker');
    }
  }

  function setConfigurationSubmit(form) {
    setData((p) => ({
      ...p,
      config: form,
    }));
    setStep('onboardingTracker');
  }

  function onboardingTrackerOnHelp() {
    setStep('activateHelp');
    setData((p) => ({
      ...p,
      helpTriggered: true,
    }));
  }

  function onDeviceInfo(deviceInfo) {
    setData((p) => ({
      ...p,
      deviceInfo
    }));
  }

  function onParameters(parameters) {
    setData((p) => ({
      ...p,
      parameters
    }));
  }

  function onSynthesis(synthesis) {
    setData((p) => ({
      ...p,
      synthesis
    }));
  }

  return (
    <main>
      {toasts.map((t, i) => (
        <Alert
          key={i}
          variation={t.type}
          isDismissible={true}
          hasIcon={true}
          heading={t.title}
          style={{
            zIndex: 1,
            position: "fixed",
            bottom: "1em",
            right: "1em"
          }}
        >
          {t.message}
        </Alert>
      ))}
      {step === 'accountLock' && <Alert variation="error">Your account is not ready yet. Please sign out and try again later.</Alert>}
      {step === 'collectAssetInfo' && <CollectAssetInfo
        {...data.assetInfo}
        onSubmit={collectAssetInfoSubmit}
        onBluetooth={scanBluetooth}
        pushToast={pushToast}
      />}
      {step === 'bluetooth' && <BluetoothScan
        {...data.assetInfo}
        onBack={() => setStep('collectAssetInfo')}
        pushToast={pushToast}
      />}
      {step === 'activeInstances' && <ActiveInstances
        {...data.assetInfo}
        instances={JSON.parse(data.instances)}
        onBack={() => setStep('collectAssetInfo')}
        onNext={() => setStep('collectRegisterInfo')}
        onSubmit={activeInstancesSubmit}
        pushToast={pushToast}
      />}
      {step === 'collectRegisterInfo' && <CollectRegisterInfo
        customers={JSON.parse(data.customers)}
        instances={JSON.parse(data.instances)}
        assetId={data.assetInfo.assetId}
        onBack={() => setStep('collectAssetInfo')}
        onSubmit={collectRegisterInfoSubmit}
        pushToast={pushToast}
      />}
      {step === 'assetDiscovery' && <AssetDiscovery
        {...data.assetInfo}
        instance={data.instance}
        skipConfirmation={data.helpTriggered}
        onBack={() => setStep('collectRegisterInfo')}
        onNext={() => setStep('setOptions')}
        onSubmit={assetDiscoverySubmit}
        pushToast={pushToast}
      />}
      {step === 'setOptions' && <SetOptions
        {...data.options}
        onBack={() => setStep('collectRegisterInfo')}
        onSubmit={setOptionsSubmit}
        pushToast={pushToast}
      />}
      {step === 'setConfiguration' && <SetConfiguration
        config={data.config}
        onBack={() => setStep('setOptions')}
        onSubmit={setConfigurationSubmit}
        pushToast={pushToast}
      />}
      {step === "onboardingTracker" && <OnboardingTracker
        {...data}
        onHelp={onboardingTrackerOnHelp}
        onNext={() => setStep('assetView')}
        onCancel={() => window.location.reload()}
        pushToast={pushToast}
      />}
      {step === 'activateHelp' && <ActivateHelp
        onRegister={() => setStep('homingRegister')}
        onDiscover={() => setStep('assetDiscovery')}
        onBack={() => setStep('setOptions')}
        onNext={() => setStep('onboardingTracker')}
        pushToast={pushToast}
      />}
      {step === 'homingRegister' && <HomingRegister
        {...data}
        onBack={() => setStep('setOptions')}
        onNext={() => setStep('assetDiscovery')}
        pushToast={pushToast}
      />}
      {step === 'assetView' && <AssetView
        {...data}
        onBack={() => setStep('collectAssetInfo')}
        onNext={() => setStep('validate')}
        onUpdate={() => setStep('ota')}
        onDetail={() => setStep('assetDetail')}
        onDashboard={() => setStep('dashboard')}
        onAdvanced={() => setStep('advanced')}
        onDeviceInfo={onDeviceInfo}
        onParameters={onParameters}
        onSynthesis={onSynthesis}
        pushToast={pushToast}
      />}
      {step === 'validate' && <ValidationFlow
        {...data}
        onBack={() => setStep('assetView')}
        onNext={() => setStep('assetView')}
        pushToast={pushToast}
      />}
      {step === 'dashboard' && <Dashboard
        {...data}
        onBack={() => setStep('assetView')}
        onNext={() => setStep('assetView')}
        onSynthesis={onSynthesis}
        pushToast={pushToast}
      />}
      {step === 'ota' && <FirmwareUpdate
        {...data}
        onBack={() => setStep('assetView')}
        onNext={() => setStep('assetView')}
        pushToast={pushToast}
      />}
      {step === 'assetDetail' && <AssetDetail
        {...data}
        onBack={() => setStep('assetView')}
        onNext={() => setStep('assetView')}
        pushToast={pushToast}
      />}
      {step === 'advanced' && <AdvancedOptions
        {...data}
        onBack={() => setStep('assetView')}
        onNext={() => setStep('assetView')}
        pushToast={pushToast}
      />}
    </main>
  )
}