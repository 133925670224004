import { Component } from "react";
import CustomIcon from './CustomIcon';

class HeadlightIcon extends Component {
  render() {
    const size = this.props.size || 24;
    const color = this.props.headlight ? 'green' : 'currentColor';

    return (
      <CustomIcon name="headlight" color={color} size={size} />
    )
  }
}

export default HeadlightIcon