import { Component } from "react";
import { Grid, Card, Button } from '@aws-amplify/ui-react';
import DensoLogo from '../icons/logo.svg';

class Header extends Component {
  render() {
    return (
      <Grid templateColumns={{
        base: "4fr 0.5fr",
        large: "3fr 1fr"
      }}>
        <Card
          columnStart="1"
          columnEnd="3"
        >
          <img src={DensoLogo} alt="DENSO Logo" />
        </Card>
        <Card
          columnStart="3"
          columnEnd="-1"
        >
          <Button variation="menu" onClick={this.props.changeUnit}>
            {this.props.unit}
          </Button>
          <Button onClick={this.props.signOut}>
            Sign Out
          </Button>
        </Card>
      </Grid>
    )
  }
}

export default Header
