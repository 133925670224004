import { Component } from "react";
import { Circle, CircleDashed, CircleX, CircleCheck } from 'tabler-icons-react';

class BooleanIcon extends Component {
  render() {
    const size = this.props.size || 24;

    if (typeof this.props.value === 'undefined') {
      return (
        <CircleDashed size={size} />
      )
    }
    if (this.props.value === null) {
      return (
        <Circle size={size} />
      )
    }
    if (this.props.value) {
      return (
        <CircleCheck size={size} color="green" />
      )
    } else {
      return (
        <CircleX size={size} color="red" />
      )
    }
  }
}

export default BooleanIcon;