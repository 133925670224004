import { useEffect, useState } from 'react';
import { Grid, Card, Button } from '@aws-amplify/ui-react';
import Instructions from './workflow/Instructions';
import DoorLock from './workflow/DoorLock';
import DoorUnlock from './workflow/DoorUnlock';
import Results from './workflow/Results';

const sequence = [
  Instructions,
  DoorLock,
  DoorUnlock,
  Results
];

export default function ValidationFlow(props) {
  const [step, setStep] = useState(0);
  const [disableNext, setDisableNext] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (step > 0 && step < sequence.length - 1) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [step]);

  function onsubmit(result) {
    setResults((p) => [
      ...p,
      result
    ]);
    if (result.breaking) {
      setStep(sequence.length - 1);
    } else {
      setDisableNext(false);
    }
  }

  return (
    <Grid templateColumns={{
      base: '0fr 1fr 0fr',
      large: '1fr 2fr 1fr'
    }}>
      <Card
        columnStart='2'
        columnEnd='3'
        style={{ textAlign: 'center' }}
      >
        {sequence.map((Com, i) => (<div key={i}>{ i === step && <Com {...props} results={results} onSubmit={onsubmit} /> }</div>))}
      </Card>
      <Card
        columnStart='2'
        columnEnd='3'
      >
        <Button variation='primary' onClick={() => step === sequence.length -1 ? props.onNext() : setStep(step + 1)} isDisabled={disableNext}>Next</Button>
        <Button variation='secondary' style={{marginLeft: '0.5em'}} onClick={() => step === 0 ? props.onBack() : setStep(step - 1)}>Back</Button>
      </Card>
    </Grid>
  )
}