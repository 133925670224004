import { Component } from "react";
import CustomIcon from './CustomIcon';

class SeatbeltIcon extends Component {
  render() {
    const size = this.props.size || 24;
    const color = this.props.seatbelt ? 'red' : 'currentColor';

    return (
      <CustomIcon name="seat-belt" color={color} size={size} />
    )
  }
}

export default SeatbeltIcon