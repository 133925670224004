import { Component } from "react";
import CustomIcon from './CustomIcon';

class DoorOpenIcon extends Component {
  render() {
    const size = this.props.size || 24;
    const color = this.props.doorStatus.frontDriver.ajar || this.props.doorStatus.frontPassenger.ajar ? 'red' : 'currentColor';

    return (
      <CustomIcon name="aui-icon-door-open-large" color={color} size={size} />
    )
  }
}

export default DoorOpenIcon