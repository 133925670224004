import { useState } from 'react';
import { Grid, Card, SelectField, StepperField, SwitchField, Divider, Button, Expander, ExpanderItem } from '@aws-amplify/ui-react';

export default function SetConfiguration(props) {
  const [config, setConfig] = useState(props.config);

  function handleSubmit() {
    props.onSubmit(config);
  }

  function handleChange(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (event.target.name.includes('.')) {
      setConfig((p) => ({
        ...p,
        [event.target.name.split('.')[0]]: {
          ...p[event.target.name.split('.')[0]],
          [event.target.name.split('.')[1]]: value
        }
      }));
    } else {
      setConfig((p) => ({
        ...p,
        [event.target.name]: value
      }));
    }
  }

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 2fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <Expander type="single" isCollapsible={true}>
          <ExpanderItem title="State Refresh" value="state-refresh">
            <StepperField
              label="Engine On Interval (sec)"
              value={config.stateRefresh.powerOn}
              onStepChange={(v) => handleChange({ target: { name: "stateRefresh.powerOn", value: v } })}
            />
            <StepperField
              label="Engine Off Interval (sec)"
              value={config.stateRefresh.powerOff}
              onStepChange={(v) => handleChange({ target: { name: "stateRefresh.powerOff", value: v } })}
            />
            <StepperField
              label="Idle Rental Interval (sec)"
              value={config.stateRefresh.powerOffReserved}
              onStepChange={(v) => handleChange({ target: { name: "stateRefresh.powerOffReserved", value: v } })}
            />
            <StepperField
              label="Charging Interval (sec)"
              value={config.stateRefresh.powerOffCharging}
              onStepChange={(v) => handleChange({ target: { name: "stateRefresh.powerOffCharging", value: v } })}
            />
          </ExpanderItem>
          <ExpanderItem title="Alerts" value="alerts">
            <StepperField
              label="Engine Idle Alert (sec)"
              value={config.alert.idle}
              onStepChange={(v) => handleChange({ target: { name: "alert.idle", value: v } })}
            />
            <StepperField
              label="Speed Alert (kph)"
              value={config.alert.speed}
              onStepChange={(v) => handleChange({ target: { name: "alert.speed", value: v } })}
            />
            <StepperField
              label="Low Voltage"
              value={config.alert.battery}
              step={0.1}
              onStepChange={(v) => handleChange({ target: { name: "alert.battery", value: v } })}
            />
            <SwitchField
              label="Engine Status"
              name="alert.powerState"
              isChecked={config.alert.powerState}
              onChange={handleChange}
              style={{margin: "1em"}}
            />
            <SwitchField
              label="Vehicle Alarm"
              name="alert.alarm"
              isChecked={config.alert.alarm}
              onChange={handleChange}
              style={{margin: "1em"}}
            />
          </ExpanderItem>
          <ExpanderItem title="Firmware Update" value="ota">
            <StepperField
              label="Minimum Voltage"
              value={config.firmwareUpdateCriteria.batteryVoltage}
              step={0.1}
              onStepChange={(v) => handleChange({ target: { name: "firmwareUpdateCriteria.batteryVoltage", value: v } })}
            />
            <SwitchField
              label="Download On Edge"
              name="firmwareUpdateCriteria.downloadOnEdge"
              isChecked={config.firmwareUpdateCriteria.downloadOnEdge}
              onChange={handleChange}
              style={{margin: "1em"}}
            />
            <br/>
            <SwitchField
              label="Not Reserved"
              name="firmwareUpdateCriteria.notReserved"
              isChecked={config.firmwareUpdateCriteria.notReserved}
              onChange={handleChange}
              style={{margin: "1em"}}
            />
            <br/>
            <SwitchField
              label="Engine Off"
              name="firmwareUpdateCriteria.powerOff"
              isChecked={config.firmwareUpdateCriteria.powerOff}
              onChange={handleChange}
              style={{margin: "1em"}}
            />
          </ExpanderItem>
        </Expander>
        <Divider style={{margin: "1em"}} />
        <StepperField
          label="Engine Start Window (sec)"
          value={config.powerActivateTimeout}
          onStepChange={(v) => handleChange({ target: { name: "powerActivateTimeout", value: v } })}
        />
        <SelectField
          label="Immobilizer Mode"
          options={["NORMAL", "PARTIAL", "FULL"]}
          name="immobilizationMode"
          value={config.immobilizationMode}
          onChange={handleChange}
          style={{marginBottom: "1em"}}
        ></SelectField>
        <SwitchField
          label="Tracking Enabled"
          name="trackingEnabled"
          isChecked={config.trackingEnabled}
          onChange={handleChange}
        />
        <br/>
        <Button
          variation="primary"
          style={{marginTop: "1em"}}
          onClick={handleSubmit}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='configurationNext'
        >Next</Button>
        <Button
          variation="secondary"
          style={{marginLeft: "0.5em"}}
          onClick={() => props.onBack()}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='configurationBack'
        >Back</Button>
      </Card>
    </Grid>
  )
}