import { useState } from 'react';
import { Analytics } from 'aws-amplify';
import { Grid, Card, TextField, Button } from '@aws-amplify/ui-react';

export default function CheckAssetInfo(props) {
  const [assetId, setAssetId] = useState(props.assetId);
  const [deviceId, setDeviceId] = useState(props.deviceId);
  const [assetErrorText, setAssetErrorText] = useState();
  const [deviceErrorText, setDeviceErrorText] = useState();

  function handleSubmit(e) {
    if (e.key !== 'Enter' && e.type !== 'click') return;

    if (assetId.length !== 17) {
      setAssetErrorText(`VIN must be 17 characters, input ${assetId.length}`);
    } else if (deviceId && deviceId.length !== 12) {
      setDeviceErrorText(`CCM ID must be 12 digits, input ${deviceId.length}`)
    } else {
      Analytics.record({
        name: 'assetInfoSubmit',
        attributes: { assetId, deviceId }
      });
      props.onSubmit({
        assetId,
        deviceId
      });
    }
  }

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 1fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <TextField
          label="Asset ID"
          descriptiveText="To get started, please enter the VIN"
          placeholder="VIN123"
          errorMessage={assetErrorText}
          hasError={assetErrorText}
          value={assetId}
          onChange={(e) => setAssetId(e.target.value)}
          onKeyDown={handleSubmit}
          style={{ marginBottom: "1em" }}
        />
        <TextField
          label="Device (CCM) ID"
          descriptiveText="(optional)"
          placeholder="CCM123"
          errorMessage={deviceErrorText}
          hasError={deviceErrorText}
          value={deviceId}
          onChange={(e) => setDeviceId(e.target.value)}
        />
        <Button
          variation="primary"
          style={{marginTop: "1em"}}
          onClick={handleSubmit}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='assetInfoNext'
        >Next</Button>
      </Card>
      {navigator.bluetooth && navigator.bluetooth.requestLEScan && <Card
          columnStart="2"
          columnEnd="3"
        >
          <Button
            variation="primary"
            style={{marginTop: "1em"}}
            isFullWidth={true}
            onClick={() => props.onBluetooth()}
            data-amplify-analytics-on='click'
            data-amplify-analytics-name='assetInfoNext'
          >Detect Nearby</Button>
      </Card>}
    </Grid>
  )
}