import { createContext } from 'react';

const conversions = {
  metric: {
    display: 'Metric',
    speed: {
      display: 'kph',
      convert: (fromMetric: number) => Math.round(fromMetric * 10) / 10
    },
    distance: {
      display: 'km',
      convert: (fromMetric: number) => Math.round(fromMetric * 1).toLocaleString('en-US')
    },
    pressure: {
      display: 'kpa',
      convert: (fromMetric: number) => Math.round(fromMetric * 1)
    },
    liquid: {
      display: 'l',
      convert: (fromMetric: number) => Math.round(fromMetric * 100) / 100
    }
  },
  imperial: {
    display: 'Imperial',
    speed: {
      display: 'mph',
      convert: (fromMetric: number) => Math.round(fromMetric * 10 * 0.6213712) / 10
    },
    distance: {
      display: 'mi',
      convert: (fromMetric: number) => Math.round(fromMetric * 0.6213712).toLocaleString('en-US')
    },
    pressure: {
      display: 'psi',
      convert: (fromMetric: number) => Math.round(fromMetric * 0.1450377)
    },
    liquid: {
      display: 'gal',
      convert: (fromMetric: number) => Math.round(fromMetric * 100 * 0.2199693) / 100
    }
  }
}

const UnitContext = createContext(conversions.imperial);

export { conversions };
export default UnitContext;