import { useState, useEffect } from "react";
import { Grid, Card, Heading, Text, Button } from '@aws-amplify/ui-react';
import StatusIcon from "../icons/StatusIcon";
import { API } from 'aws-amplify';

export default function OnboardingTracker(props) {
  const [step, setStep] = useState('');
  const [registerStep, setRegisterStep] = useState({ status: null, message: '...' });
  const [homingStep, setHomingStep] = useState({ status: null, message: '...' });
  const [activateStep, setActivateStep] = useState({ status: null, message: '...' });
  const [configureStep, setConfigureStep] = useState({ status: null, message: '...' });
  const [synthesisStep, setSynthesisStep] = useState({ status: null, message: '...' });
  const [targetRequestId, setTargetRequestId] = useState();
  const [ticker, setTicker] = useState(0);
  const [disableHelp, setDisableHelp] = useState(true);
  const [disableNext, setDisableNext] = useState(true);

  useEffect(() => {
    if (step === 'start' && props.options.cleanSlate) {
      API.post('apie9a86f09', `/api/${props.instance}/unregister`, { body: {
        customerDeviceId: props.vehicleInfo.customerDeviceId,
        customerId: props.vehicleInfo.customerId,
        requestId: '697f36b9-e586-0b82-a76e-15e6bfd39ebf'
      }})
        .then(_ => {
          setStep('register');
        })
        .catch(err => {
          console.log(err);
          setRegisterStep({ status: "error", message: "Something went wrong" });
        });
    } else if (step === 'start') {
      setStep('register');
    }

    if (step === 'register') {
      console.log(props.vehicleInfo);
      API.post('apie9a86f09', `/api/${props.instance}/register`, { body: props.vehicleInfo })
      .then(_ => {
        setRegisterStep({ status: "success", message: "Successfully registered" });
        setStep('sendRelocate');
      })
      .catch(err => {
        console.log(err)
        setRegisterStep({ status: "error", message: "Something went wrong" });
      });
    }

    if (step === 'sendRelocate' && props.discovery) {
      setHomingStep({ status: "pending", message: "Sending command..." });
      API.post('apie9a86f09', `/api/${props.instance}/homing/relocate`, { body: {
        action: 'status',
        discovery: props.discovery,
        customer: props.vehicleInfo.customerId
      }})
      .then((data) => {
        console.log(data);
        setHomingStep({ status: "success", message: `Sent change environment: ${data.map((v) => v.transaction_id)}` });
        setStep('checkActivation');
      })
      .catch(err => {
        console.log(err)
        setHomingStep({ status: "error", message: "Something went wrong" });
      });
    } else if (step === 'sendRelocate') {
      setStep('checkActivation');
    }

    if (step === 'checkActivation') {
      setActivateStep({ status: "waiting", message: "Waiting for activation..." });
      setDisableHelp(false);
      API.get('apie9a86f09', `/api/${props.instance}/vehicles/${props.vehicleInfo.customerDeviceId}/info`)
      .then((deviceInfo) => {
        console.log(deviceInfo);
        if (!deviceInfo?.active) {
          setTimeout(() => setTicker(ticker + 1), 10000)
          return;
        }
        setActivateStep({ status: "success", message: `Recieved activation from ${deviceInfo.deviceId}` });
        setDisableHelp(true);
        setStep('cleanupRelocate');
      })
      .catch(err => {
        console.log(err)
        setActivateStep({ status: "error", message: "Something went wrong" });
      });
    }

    if (step === 'cleanupRelocate' && props.discovery) {
      API.post('apie9a86f09', `/api/${props.instance}/homing/unregister`, { body: {
        customerDeviceId: props.vehicleInfo.customerDeviceId,
        customerId: props.vehicleInfo.customerId,
        requestId: '697f36b9-a586-0b82-a75e-15e6bfd39ebf'
      }})
      .then((data) => {
        console.log(data);
        setHomingStep({ status: "success", message: 'Cleaned up' });
        setStep('sendConfig');
      })
      .catch(err => {
        console.log(err)
        setHomingStep({ status: "error", message: "Something went wrong" });
      });
    } else if (step === 'cleanupRelocate') {
      setStep('sendConfig');
    }

    if (step === 'sendConfig' && props.options.sendConfig) {
      setConfigureStep({ status: "pending", message: "Sending command..." });
      API.post('apie9a86f09', `/api/${props.instance}/command`, { body: {
        action: 'configure',
        vehicle: props.vehicleInfo.customerDeviceId,
        customer: props.vehicleInfo.customerId,
        payload: props.config
      }})
      .then((data) => {
        setConfigureStep({ status: "waiting", message: "Waiting for confirmation..." });
        setTargetRequestId(data.transaction_id);
        setStep('waitConfig');
      })
      .catch(err => {
        console.log(err)
        setConfigureStep({ status: "error", message: "Something went wrong" });
      });
    } else if (step === 'sendConfig') {
      setConfigureStep({ status: "skip", message: "Skipped" });
      setStep('waitSynthesis');
    }

    if (step === 'waitConfig') {
      API.get('apie9a86f09', `/api/${props.instance}/vehicles/${props.vehicleInfo.customerDeviceId}/synthesis`)
      .then((synthesis) => {
        console.log(synthesis);
        if (!Object.keys(synthesis).length || synthesis.requestId !== targetRequestId) {
          setTimeout(() => setTicker(ticker + 1), 10000);
          return;
        }
        setStep('checkConfig');
      })
      .catch(err => {
        console.log(err)
        setConfigureStep({ status: "error", message: "Something went wrong" });
      });
    }

    if (step === 'checkConfig') {
      API.get('apie9a86f09', `/api/${props.instance}/vehicles/${props.vehicleInfo.customerDeviceId}/parameters`)
      .then((parameters) => {
        console.log(parameters);
        if (!Object.keys(parameters).length) {
          setTimeout(() => setTicker(ticker + 1), 10000);
          return;
        }
        setConfigureStep({ status: "success", message: "Parameters configured" });
        setStep('waitSynthesis');
      })
      .catch(err => {
        console.log(err)
        setConfigureStep({ status: "error", message: "Something went wrong" });
      });
    }
    if (step === 'waitSynthesis' && props.options.delayCheck) {
      setSynthesisStep({ status: "pending", message: "Waiting..." });
      setTimeout(() => setStep('sendSynthesis'), 45000);
    } else if (step === 'waitSynthesis') {
      setStep('sendSynthesis');
    }

    if (step === 'sendSynthesis') {
      setSynthesisStep({ status: "pending", message: "Sending command..." });
      API.post('apie9a86f09', `/api/${props.instance}/command`, { body: {
        action: 'status',
        vehicle: props.vehicleInfo.customerDeviceId,
        customer: props.vehicleInfo.customerId
      }})
      .then((data) => {
        setSynthesisStep({ status: "waiting", message: "Waiting for response..." });
        setTargetRequestId(data.transaction_id);
        setStep('checkSynthesis');
      })
      .catch(err => {
        console.log(err)
        setSynthesisStep({ status: "error", message: "Something went wrong" });
      });
    }

    if (step === 'checkSynthesis') {
      API.get('apie9a86f09', `/api/${props.instance}/vehicles/${props.vehicleInfo.customerDeviceId}/synthesis`)
      .then((synthesis) => {
        console.log(synthesis);
        if (!Object.keys(synthesis).length || synthesis.requestId !== targetRequestId) {
          setTimeout(() => setTicker(ticker + 1), 10000);
          return;
        }
        setSynthesisStep({ status: "success", message: `Detected syntehsis ${synthesis.requestId}` });
        setDisableNext(false);
      })
      .catch(err => {
        console.log(err)
        setSynthesisStep({ status: "error", message: "Something went wrong" });
      });
    }
  }, [step, ticker]);

  useEffect(() => {
    setRegisterStep({ status: "pending", message: "Registering..." });
    setStep('start');
  }, []);

  return (
    <Grid templateColumns={{
      base: "0.2fr 0.1fr 1fr 0fr",
      large: "1.5fr 0.1fr 1fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <StatusIcon status={registerStep.status} />
      </Card>
      <Card
        columnStart="3"
        columnEnd="4"
      >
        <Heading level={3}>Register</Heading>
        <Text>{registerStep.message}</Text>
      </Card>
      {props.discovery && (
        <Card
          columnStart="2"
          columnEnd="3"
        >
          <StatusIcon status={homingStep.status} />
        </Card>
      )}
      {props.discovery && (
        <Card
          columnStart="3"
          columnEnd="4"
        >
          <Heading level={3}>Relocate</Heading>
          <Text>{homingStep.message}</Text>
        </Card>
      )}
      <Card
        columnStart="1"
        columnEnd="2"
      >
        <Button
          style={{ float: "right" }}
          variation="secondary"
          onClick={() => props.onHelp()}
          isDisabled={disableHelp}
        >
          Help
        </Button>
      </Card>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <StatusIcon status={activateStep.status} />
      </Card>
      <Card
        columnStart="3"
        columnEnd="4"
      >
        <Heading level={3}>Activate</Heading>
        <Text>{activateStep.message}</Text>
      </Card>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <StatusIcon status={configureStep.status} />
      </Card>
      <Card
        columnStart="3"
        columnEnd="4"
      >
        <Heading level={3}>Configure</Heading>
        <Text>{configureStep.message}</Text>
      </Card>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <StatusIcon status={synthesisStep.status} />
      </Card>
      <Card
        columnStart="3"
        columnEnd="4"
      >
        <Heading level={3}>Synthesis</Heading>
        <Text>{synthesisStep.message}</Text>
      </Card>
      <Card
        columnStart="2"
        columnEnd="4"
      >
        <Button
          variation="primary"
          onClick={() => props.onNext()}
          isDisabled={disableNext}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='onboardingTrackerNext'
        >Next</Button>
        <Button
          variation="secondary"
          style={{marginLeft: "0.5em"}}
          onClick={() => props.onCancel()}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='onboardingTrackerBack'
        >Cancel</Button>
      </Card>
    </Grid>
  )
}
