import { Table, TableHead, TableRow, TableCell, TableBody } from '@aws-amplify/ui-react';
import TimeAgo from '../utils/TimeAgo.ts';
import SignalIcon from "../icons/SignalIcon";
import SeatbeltIcon from '../icons/SeatbeltIcon';
import HandbrakeIcon from '../icons/HandbrakeIcon';
import HeadlightIcon from '../icons/HeadlightIcon';
import DoorOpenIcon from '../icons/DoorOpenIcon';
import { useContext } from 'react';
import UnitContext from '../utils/UnitContext.ts';

export default function DataTable(props) {
  const { data } = props;
  const unit = useContext(UnitContext);

  return (
    <Table highlightOnHover={true}>
      <TableHead>
        <TableRow>
          <TableCell as="th">Timestamp</TableCell>
          <TableCell as="th">Event Source</TableCell>
          <TableCell as="th">Originator</TableCell>
          <TableCell as="th">Ignition</TableCell>
          <TableCell as="th">Fuel Information</TableCell>
          <TableCell as="th">Vehicle Status</TableCell>
          <TableCell as="th">Odometer ({unit.distance.display})</TableCell>
          <TableCell as="th">Speed ({unit.speed.display})</TableCell>
          <TableCell as="th">Signal</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {data.map((e, i) => (
        <TableRow key={i} onClick={() => console.log(i)}>
          <TableCell>{TimeAgo(e.timestamp)} ago</TableCell>
          <TableCell>{e.eventSource}</TableCell>
          <TableCell>{e.originator.type}</TableCell>
          <TableCell>{e.customerDevice.ignitionState}</TableCell>
          <TableCell>{e.customerDevice.fuelLevelPercent || '-'}%  {unit.distance.convert(e.customerDevice.fuelRangeKm) || '-'} {unit.distance.display}</TableCell>
          <TableCell>
            <HandbrakeIcon handbrake={e.customerDevice.handbrake} size={28} />
            <SeatbeltIcon seatbelt={e.customerDevice.seatBeltLight} size={28} />
            <HeadlightIcon headlight={e.customerDevice.lightStatus} size={28} />
            <DoorOpenIcon doorStatus={e.customerDevice.doorStatus} size={28} />
          </TableCell>
          <TableCell>{unit.distance.convert(e.customerDevice.odometerKm)} {unit.distance.display}</TableCell>
          <TableCell>{unit.speed.convert(e.customerDevice.speedKph)} {unit.speed.display}</TableCell>
          <TableCell><SignalIcon rssi={e.device.networkInformation.rssi} offline={e.device.offline} /></TableCell>
        </TableRow>
      ))}
      </TableBody>
    </Table>
  )
}