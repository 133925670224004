import { useState } from 'react';
import { Grid, Card, Text, Button, RadioGroupField, Radio } from '@aws-amplify/ui-react';

export default function ActivateHelp(props) {
  const [showActivateHelp, setShowActivateHelp] = useState(false);
  const [radioOption, setRadioOption] = useState('');

  function handleSubmit() {
    if (radioOption === 'register') {
      props.onRegister();
    } else if (radioOption === 'discovery') {
      props.onDiscover();
    } else if (radioOption === 'retry') {
      setShowActivateHelp(true);
    }
  }

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 2fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        {showActivateHelp && (
          <div>
            <Text style={{marginBottom: "1em"}}>Please ensure the activation procedure was followed correctly and the cellular connection is acceptable.</Text>
            <Button
              variation="primary"
              style={{marginTop: "1em"}}
              onClick={() => props.onNext()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='activateHelpNext'
            >Next</Button>
            <Button
              variation="secondary"
              style={{marginLeft: "0.5em"}}
              onClick={() => props.onBack()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='activateHelpBack'
            >Back</Button>
          </div>
        )}
        {!showActivateHelp && (
          <div>
            <Text style={{marginBottom: "1em"}}>Please select the option that best describes what occured.</Text>
            <RadioGroupField label="LED Flash Pattern" value={radioOption} onChange={(e) => setRadioOption(e.target.value)}>
              <Radio value="register">Blue then Red</Radio>
              <Radio value="discovery">Blue then Green</Radio>
              <Radio value="retry">Just Blue</Radio>
              <Radio value="blank">Nothing</Radio>
            </RadioGroupField>
            <Button
              variation="primary"
              style={{marginTop: "1.5em"}}
              onClick={handleSubmit}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='activateHelpNext'
            >Next</Button>
            <Button
              variation="secondary"
              style={{marginLeft: "0.5em"}}
              onClick={() => props.onBack()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='activateHelpBack'
            >Back</Button>
          </div>
        )}
      </Card>
    </Grid>
  )
}