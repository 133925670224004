import { useState } from 'react';
import { API } from 'aws-amplify';
import { Grid, Card, Flex, Button, View, Loader, SelectField } from '@aws-amplify/ui-react';
import {
  Power,
  FilesOff,
  SettingsAutomation,
  ChevronsRight
} from 'tabler-icons-react';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1); 

const instanceMapper = {
  'dev-us1': {
    environment: 'DEV',
    region: 'US'
  },
  'uat-us1': {
    environment: 'UAT',
    region: 'US'
  },
  'uat-us2': {
    environment: 'UAT',
    region: 'US'
  },
  'uat-eu1': {
    environment: 'UAT',
    region: 'EU'
  },
  'prod-us1': {
    environment: 'PROD',
    region: 'US'
  },
  'prod-eu1': {
    environment: 'PROD',
    region: 'EU'
  }
}

export default function CheckAssetInfo(props) {
  const [showLoading, setShowLoading] = useState(false);
  const [moveInstance, setMoveInstance] = useState(JSON.parse(props.instances)[0]);

  async function sendCommand(action) {
    setShowLoading(true)
    const response = await API.post('apie9a86f09', `/api/${props.instance}/command`, { body: {
      action,
      ...(action === 'configure' && {
        payload: {
          stateRefresh: {
            powerOn: 60,
            powerOffReserved: 3600,
            powerOff: 7200,
            powerOffCharging: 600
          },
          alert: {
            powerState: true,
            alarm: false,
            speed: 120,
            battery: 10,
            idle: 600
          },
          firmwareUpdateCriteria: {
            powerOff: true,
            notReserved: true,
            downloadOnEdge: false,
            batteryVoltage: 9.5
          },
          powerActivateTimeout: 300,
          immobilizationMode: 'NORMAL',
          trackingEnabled: true
        }
      }),
      ...(action === 'move' && {
        payload: instanceMapper[moveInstance]
      }),
      vehicle: props.deviceInfo.customerDeviceId,
      customer: props.instance === 'demo-us1' ? 'nextgen' : props.deviceInfo.customerId
    }});
    props.pushToast(
      'success',
      `${capitalize(action)} Sent`,
      `Id: ${response.transaction_id || response.requestId}`
    );
    props.onNext();
  }

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 1fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        {showLoading && <Loader variation="linear" />}
        {!showLoading && (
        <div>
          <Card>
            <Grid templateColumns="0.5fr 2fr">
              <FilesOff size={48} />
              <Flex
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <View style={{marginLeft: "0.5em", whiteSpace: 'pre-wrap'}}>
                <Button
                  onClick={() => sendCommand('clear')}
                  data-amplify-analytics-on='click'
                  data-amplify-analytics-name='clearKeysCommand'
                >Clear Keys</Button>
                </View>
              </Flex>
            </Grid>
          </Card>
          <Card>
            <Grid templateColumns="0.5fr 2fr">
              <SettingsAutomation size={48} />
              <Flex
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <View style={{marginLeft: "0.5em", whiteSpace: 'pre-wrap'}}>
                <Button
                  onClick={() => sendCommand('configure')}
                  data-amplify-analytics-on='click'
                  data-amplify-analytics-name='configCommand'
                >Default Config</Button>
                </View>
              </Flex>
            </Grid>
          </Card>
          <Card>
            <Grid templateColumns="0.5fr 2fr">
              <Power size={48} />
              <Flex
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <View style={{marginLeft: "0.5em", whiteSpace: 'pre-wrap'}}>
                <Button
                  onClick={() => sendCommand('reboot')}
                  data-amplify-analytics-on='click'
                  data-amplify-analytics-name='rebootCommand'
                >Reboot</Button>
                </View>
              </Flex>
            </Grid>
          </Card>
          <Card>
            <Grid templateColumns="0.5fr 2fr">
              <ChevronsRight size={48} />
              <Flex
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <View style={{marginLeft: "0.5em", whiteSpace: 'pre-wrap'}}>
                <Button
                  onClick={() => sendCommand('move')}
                  data-amplify-analytics-on='click'
                  data-amplify-analytics-name='moveCommand'
                >Move</Button>
                </View>
                <SelectField
                  options={JSON.parse(props.instances).filter(instance => instance !== props.instance)}
                  onChange={(e) => setMoveInstance(e.target.value)}
                  style={{marginLeft: "0.5em"}}
                />
              </Flex>
            </Grid>
          </Card>
          <Button
            variation="secondary"
            style={{marginTop: "2em", marginLeft: "0.5em"}}
            onClick={() => props.onBack()}
            data-amplify-analytics-on='click'
            data-amplify-analytics-name='advancedBack'
          >Back</Button>
        </div>
        )}
      </Card>
    </Grid>
  )
}