import { Grid, Card, Flex, View } from '@aws-amplify/ui-react';
import {
  GasStation,
  ChargingPile,
  Car,
  BatteryAutomotive,
  AntennaBarsOff,
  AntennaBars1,
  AntennaBars2,
  AntennaBars3,
  AntennaBars4,
  AntennaBars5,
  PlugConnected,
  PlugConnectedX,
  BatteryCharging2,
  Key,
  KeyOff,
  IdBadge,
  IdBadgeOff
} from 'tabler-icons-react';
import { useContext } from 'react';
import UnitContext from '../utils/UnitContext.ts';
import SeatbeltIcon from '../icons/SeatbeltIcon';
import HandbrakeIcon from '../icons/HandbrakeIcon';
import HeadlightIcon from '../icons/HeadlightIcon';
import DoorOpenIcon from '../icons/DoorOpenIcon';

export default function DashboardStatus(props) {
  const unit = useContext(UnitContext);

  const elements = [
    {
      icon: SeatbeltIcon,
      props: {
        seatbelt: props.customerDevice.seatBeltLight
      },
      content: 'Seatbelt'
    },
    {
      icon: HandbrakeIcon,
      props: {
        handbrake: props.customerDevice.handbrake
      },
      content: 'Handbrake'
    },
    {
      icon: HeadlightIcon,
      props: {
        headlight: props.customerDevice.lightStatus
      },
      content: 'Headlight'
    },
    {
      icon: DoorOpenIcon,
      props: {
        doorStatus: props.customerDevice.doorStatus
      },
      content: 'Doors'
    },
  ];

  return (
    <Grid
      templateColumns={{ base: '1fr', large: '1fr 1fr 1fr 1fr' }}
      gap="small"
    >
      {elements.map((e, i) => (
        <Card key={i}>
          <Grid templateColumns="0.5fr 2fr">
            <e.icon {...e.props} size={48} />
            <Flex
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              <View style={{marginLeft: '0.5em', whiteSpace: 'pre-wrap'}}>{e.content}</View>
            </Flex>
          </Grid>
        </Card>
      ))}
    </Grid>
  )
}