import { Grid, Card, Flex, View, Button } from '@aws-amplify/ui-react';
import {
  GasStation,
  ChargingPile,
  Car,
  BatteryAutomotive,
  AntennaBarsOff,
  AntennaBars1,
  AntennaBars2,
  AntennaBars3,
  AntennaBars4,
  AntennaBars5,
  Lock,
  LockOpen,
  PlugConnected,
  PlugConnectedX,
  BatteryCharging2,
  ServerBolt,
  CloudUpload,
  Refresh,
  ReportSearch
} from 'tabler-icons-react';
import { useContext } from 'react';
import UnitContext from '../utils/UnitContext.ts';

export default function VehicleStatus(props) {
  const { rssi } = props.device.networkInformation;
  const { evCharging, connectedToCharger } = props.customerDevice
  const unit = useContext(UnitContext);

  const elements = [
    {
      icon: GasStation,
      content: `${props.customerDevice.fuelLevelPercent || '-'}% ${unit.distance.convert(props.customerDevice.fuelRangeKm) || '-'} ${unit.distance.display} ${unit.liquid.convert(props.customerDevice.fuelLiters) || '-'} ${unit.liquid.display}`
    },
    {
      icon: ChargingPile,
      content: `${props.customerDevice.evChargePercent || '-'}% ${unit.distance.convert(props.customerDevice.evChargeRangeKm) || '-'} ${unit.distance.display}`
    },
    {
      icon: evCharging ? BatteryCharging2 : connectedToCharger ? PlugConnected : PlugConnectedX,
      content: `${connectedToCharger ? 'connected to charger' : 'not connected to charger'}${connectedToCharger && evCharging ? ' and ' : ' '}${evCharging ? 'charging' : ''}`
    },
    {
     icon: Car,
     content: `${unit.distance.convert(props.customerDevice.odometerKm)} ${unit.distance.display} \n ${props.customerDevice.ignitionState}`
    },
    {
     icon: BatteryAutomotive,
     content: `${Math.round(props.customerDevice.batteryVoltage * 100) / 100} v`
    },
    {
     icon: props.device.offline ? AntennaBarsOff : rssi > 20 ? AntennaBars5 : rssi > 15 ? AntennaBars4 : rssi > 10 ? AntennaBars3 : rssi > 5 ? AntennaBars2 : AntennaBars1,
     content: `${props.device.networkInformation.provider} \n ${props.device.networkInformation.registrationStatus} & ${props.device.networkInformation.systemMode}`
    },
    {
     icon: Lock,
     content: <Button
        onClick={() => props.sendCommand('lock')}
        data-amplify-analytics-on='click'
        data-amplify-analytics-name='lockCommand'
      >Lock</Button>
    },
    {
      icon: LockOpen,
      content: <Button
        onClick={() => props.sendCommand('unlock')}
        data-amplify-analytics-on='click'
        data-amplify-analytics-name='unlockCommand'
      >Unlock</Button>
    },
    {
      icon: Refresh,
      content: <Button
        onClick={() => props.sendCommand('status')}
        data-amplify-analytics-on='click'
        data-amplify-analytics-name='statusCommand'
      >Sync</Button>
    },
    {
      icon: ServerBolt,
      content: <Button
        onClick={() => props.doAdvanced()}
        data-amplify-analytics-on='click'
        data-amplify-analytics-name='advancedOptions'
      >Advanced</Button>
    },
    {
      icon: CloudUpload,
      content: <Button
        onClick={() => props.doUpdate()}
        data-amplify-analytics-on='click'
        data-amplify-analytics-name='firmwareUpdate'
      >OTA</Button>
    },
    {
      icon: ReportSearch,
      content: <Button
        onClick={() => props.doDetail()}
        data-amplify-analytics-on='click'
        data-amplify-analytics-name='detailedView'
      >Details</Button>
    }
  ];

  return (
    <Grid
      templateColumns={{ base: '1fr', large: '1fr 1fr 1fr' }}
      gap="small"
    >
      {elements.map((e, i) => (
        <Card key={i}>
          <Grid templateColumns="0.5fr 2fr">
            <e.icon size={48} color={e.color} />
            <Flex
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              <View style={{marginLeft: "0.5em", whiteSpace: 'pre-wrap'}}>{e.content}</View>
            </Flex>
          </Grid>
        </Card>
      ))}
    </Grid>
  )
}