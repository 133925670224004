import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Grid, Card, Loader, Heading, Text, Button, MapView, useBreakpointValue } from '@aws-amplify/ui-react';
import { Marker, AttributionControl } from 'react-map-gl';
import TimeAgo from '../utils/TimeAgo.ts';
import VehicleStatus from '../components/VehicleStatus';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1); 

export default function AssetView(props) {
  const [showLoading, setShowLoading] = useState(!props.synthesis);
  const [synthesis, setSynthesis] = useState(props.synthesis ? props.synthesis : {});
  const [parameters, setParameters] = useState({});
  const [deviceInfo, setDeviceInfo] = useState({});
  const [ticker, setTicker] = useState(0);

  useEffect(() => {
    API.get('apie9a86f09', `/api/${props.instance}/vehicles/${props.assetInfo.assetId}/info`)
      .then((data) => {
        console.log(data);
        setDeviceInfo(data);
        props.onDeviceInfo(data);
      })
      API.get('apie9a86f09', `/api/${props.instance}/vehicles/${props.assetInfo.assetId}/parameters`)
      .then((data) => {
        console.log(data);
        props.onParameters(data);
        setParameters(data);
      })
      .catch((error) => {
        console.log(error);
      })
  }, []);

  useEffect(() => {
    API.get('apie9a86f09', `/api/${props.instance}/vehicles/${props.assetInfo.assetId}/synthesis`)
      .then((data) => {
        console.log(data);
        props.onSynthesis(data);
        setSynthesis(data);
        setShowLoading(false);
      })
    setTimeout(() => setTicker(ticker + 1), 30000)
  }, [ticker]);

  async function sendCommand(action) {
    console.log(action)
    if (!deviceInfo) return;
    props.pushToast(
      'info',
      `Sending ${capitalize(action)}`,
      'processing...'
    );
    const response = await API.post('apie9a86f09', `/api/${props.instance}/command`, { body: {
      action,
      vehicle: deviceInfo.customerDeviceId,
      customer: props.instance === 'demo-us1' ? 'nextgen' : deviceInfo.customerId
    }});
    props.pushToast(
      'success',
      `${capitalize(action)} Sent`,
      `Id: ${response.transaction_id || response.requestId}`
    );
  }

  const mapSize = useBreakpointValue({
    base: { width: '90vw', height: '30vw' },
    medium: { width: '60vw', height: '20vw' }
  })

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 3fr 1fr"
    }}>
      {!showLoading && synthesis.device && synthesis.device.location.latitudeDegrees !== -1000 && synthesis.device.location.longitudeDegrees !== -1000 && (
        <Card
          columnStart="1"
          columnEnd="-1"
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '-2em',
            marginBottom: '-0.5em'
          }}
        >
          <MapView
            zoom={13}
            latitude={synthesis.device.location.latitudeDegrees}
            longitude={synthesis.device.location.longitudeDegrees}
            style={mapSize}
            attributionControl={false}
          >
            <AttributionControl customAttribution={synthesis.device.location.gpsFixTime} />
            <Marker latitude={synthesis.device.location.latitudeDegrees} longitude={synthesis.device.location.longitudeDegrees} />
          </MapView>
        </Card>
      )}
      <Card
        columnStart="2"
        columnEnd="3"
      >
        {showLoading && <Loader variation="linear" />}
        {!showLoading && !synthesis.device && 
          <div>
            <Heading level={4}>
              {props.assetInfo.assetId}
            </Heading>
            <Text>No data available, please check the hardware and try again</Text>
            <Button
              variation="secondary"
              style={{marginTop: "1em"}}
              onClick={() => props.onBack()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='assetViewBack'
            >Back</Button>
          </div> }
        {!showLoading && synthesis.device && (
          <div>
            <Heading level={4}>
              {props.assetInfo.assetId}
              {window.innerWidth > 1200 && (
                <Button
                  variation="link"
                  style={{marginTop: "1em"}}
                  onClick={() => props.onDashboard()}
                  data-amplify-analytics-on='click'
                  data-amplify-analytics-name='assetViewDashboard'
                >Dashboard</Button>
              )}
            </Heading>
            <Text variation="tertiary" style={{marginBottom: "0.5em"}}>{deviceInfo.modelYear || ''} {deviceInfo.make || ''} {deviceInfo.model || ''}</Text>
            <Text>Last seen {TimeAgo(synthesis.timestamp, true)} ago, sent {synthesis.eventSource}</Text>
            <VehicleStatus {...synthesis} {...parameters} sendCommand={sendCommand} doUpdate={props.onUpdate} doDetail={props.onDetail} doAdvanced={props.onAdvanced} />
            <Button
              variation="primary"
              style={{marginTop: "1em"}}
              onClick={() => props.onNext()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='assetViewValidate'
            >Validate</Button>
            <Button
              variation="secondary"
              style={{marginLeft: "0.5em"}}
              onClick={() => props.onBack()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='assetViewBack'
            >Back</Button>
          </div>
        )}
      </Card>
    </Grid>
  )
}