import { Heading, Text, Flex } from '@aws-amplify/ui-react';
import ResultItem from '../components/ResultItem';

export default function Results(props) {
  return (
    <main>
      <Heading level={4} style={{marginBottom: "0.5em"}}>Results</Heading>
      <Text style={{marginBottom: "1em"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Text>
      {props.results.map((r) => <ResultItem {...r} key={r.id} />)}
    </main>
  )
}