import { Component } from "react";
import {
  AntennaBarsOff,
  AntennaBars1,
  AntennaBars2,
  AntennaBars3,
  AntennaBars4,
  AntennaBars5
} from 'tabler-icons-react';

class SignalIcon extends Component {
  render() {
    const size = this.props.size || 24;

    if (this.props.offline) {
      return (
        <AntennaBarsOff size={size} color="red"/>
      )
    }
    if (this.props.rssi > 20) {
      return (
        <AntennaBars5 size={size} />
      )
    }
    if (this.props.rssi > 15) {
      return (
        <AntennaBars4 size={size}/>
      )
    }
    if (this.props.rssi > 10) {
      return (
        <AntennaBars3 size={size} />
      )
    }
    if (this.props.rssi > 5) {
      return (
        <AntennaBars2 size={size} />
      )
    }
    return (
      <AntennaBars1 size={size} color="grey" />
    )
  }
}

export default SignalIcon