import { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import { Grid, Card, Loader, Heading, Text, Button, MapView, Alert } from '@aws-amplify/ui-react';
import { Marker, AttributionControl } from 'react-map-gl';
import DashboardStatus from '../components/DashboardStatus'
import DataTable from '../components/DataTable';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1); 

export default function AssetView(props) {
  const [showLoading, setShowLoading] = useState(true);
  const [synthesis, setSynthesis] = useState({});
  const [history, setHistory] = useState([]);
  const [toasts, setToasts] = useState([]);
  const [ticker, setTicker] = useState(0);
  const mapRef = useRef();
  const { deviceInfo } = props;

  useEffect(() => {
    API.get('apie9a86f09', `/api/${props.instance}/vehicles/${props.assetInfo.assetId}/synthesis`)
      .then((data) => {
        console.log(data);
        props.onSynthesis(data);
        setSynthesis(data);
        setShowLoading(false);

        if (history[0]?.requestId !== data.requestId) {
          setHistory(p => {
            if (p.length === 50) p.pop();
            return [data, ...p];
          });
          mapRef.current.flyTo({ center: [data.device.location.longitudeDegrees, data.device.location.latitudeDegrees], zoom: 13 });
        }
      })
    setTimeout(() => setTicker(ticker + 1), 10000)
  }, [ticker]);

  return (
    <Grid templateColumns="1fr 1fr" style={{marginTop: "-3em"}}>
      {toasts.map((t, i) => (
        <Alert
          key={i}
          variation={t.type}
          isDismissible={true}
          hasIcon={true}
          heading={t.title}
          style={{
            zIndex: 1,
            position: "fixed",
          }}
        >
          {t.message}
        </Alert>
      ))}
      {!showLoading && synthesis.device.location.latitudeDegrees !== -1000 && synthesis.device.location.longitudeDegrees !== -1000 && (
        <Card style={{display: 'flex', justifyContent: 'center'}}>
          <MapView
            initialViewState={{
              latitude: synthesis.device.location.latitudeDegrees,
              longitude: synthesis.device.location.longitudeDegrees,
              zoom: 13,
            }}
            style={{ width: '50vw', height: '30vw' }}
            attributionControl={false}
            ref={mapRef}
          >
            <AttributionControl customAttribution={synthesis.device.location.gpsFixTime} />
            <Marker latitude={synthesis.device.location.latitudeDegrees} longitude={synthesis.device.location.longitudeDegrees} />
          </MapView>
        </Card>
      )}
      <Card>
        {showLoading && <Loader variation="linear" />}
        {!showLoading && (
          <div>
            <Heading level={4}>
              {props.assetInfo.assetId}
              <Button
                variation="menu"
                style={{float: "right"}}
                onClick={() => props.onBack()}
                data-amplify-analytics-on='click'
                data-amplify-analytics-name='assetViewBack'
              >Back</Button>
            </Heading>
            <Text variation="tertiary" style={{marginBottom: "0.5em"}}>{deviceInfo.modelYear || ''} {deviceInfo.make || ''} {deviceInfo.model || ''}</Text>
            <DashboardStatus {...synthesis} />
          </div>
        )}
      </Card>
      <Card
          columnStart="1"
          columnEnd="-1"
          style={{display: 'flex', justifyContent: 'center'}}
        >
          <DataTable data={history} />
      </Card>
    </Grid>
  )
}