import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Heading, Text, Button } from '@aws-amplify/ui-react';
import ResultItem from '../components/ResultItem';

export default function DoorLock(props) {
  const [result, setResult] = useState(null);

  useEffect(() => {
    API.post('apie9a86f09', `/api/${props.instance}/discover`, { body: {
      assetId: props.assetId,
      deviceId: props.deviceId
    }})
    .then((data) => {
      console.log(data);
    })
  }, []);

  useEffect(() => {
    if (!result) return;
    props.onSubmit({
      name: 'Door Lock',
      id: 'doorLock',
      result
    })
  }, [result]);

  function onPass() {
    setResult('pass');
  }

  function onFail() {
    setResult('fail');
  }

  return (
    <main>
      <Heading level={4} style={{marginBottom: '0.5em'}}>Door Lock</Heading>
      <Text style={{marginBottom: "1em"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Text>
      <Text>Did the doors lock?</Text>
      {!result && (
        <div>
          <Button variation='primary' style={{marginTop: '1em'}} onClick={onPass}>Yes</Button>
          <Button variation='secondary' style={{marginLeft: '0.5em'}} onClick={onFail}>No</Button>
        </div>
      )}
      {result && (
        <ResultItem result={result} name='' />
      )}
    </main>
  )
}