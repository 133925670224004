import { Grid, Card, Flex, View } from '@aws-amplify/ui-react';
import {
  GasStation,
  ChargingPile,
  Car,
  BatteryAutomotive,
  AntennaBarsOff,
  AntennaBars1,
  AntennaBars2,
  AntennaBars3,
  AntennaBars4,
  AntennaBars5,
  PlugConnected,
  PlugConnectedX,
  BatteryCharging2,
  Key,
  KeyOff,
  IdBadge,
  IdBadgeOff
} from 'tabler-icons-react';
import { useContext } from 'react';
import UnitContext from '../utils/UnitContext.ts';
import CustomIcon from '../icons/CustomIcon';

export default function DashboardStatus(props) {
  const { rssi } = props.device.networkInformation;
  const { evCharging, connectedToCharger } = props.customerDevice
  const tpmsColor = props.customerDevice.tirePressure?.tpmsLight ? 'orange' : 'currentColor';
  const unit = useContext(UnitContext);

  const elements = [
    {
      icon: GasStation,
      content: `${props.customerDevice.fuelLevelPercent || '-'}% ${unit.distance.convert(props.customerDevice.fuelRangeKm) || '-'} ${unit.distance.display} ${unit.liquid.convert(props.customerDevice.fuelLiters) || '-'} ${unit.liquid.display}`
    },
    {
      icon: ChargingPile,
      content: `${props.customerDevice.evChargePercent || '-'}% ${unit.distance.convert(props.customerDevice.evChargeRangeKm) || '-'} ${unit.distance.display}`
    },
    {
      icon: evCharging ? BatteryCharging2 : connectedToCharger ? PlugConnected : PlugConnectedX,
      content: `${connectedToCharger ? 'connected to charger' : 'not connected to charger'}${connectedToCharger && evCharging ? ' and ' : ' '}${evCharging ? 'charging' : ''}`
    },
    {
     icon: Car,
     content: `${unit.distance.convert(props.customerDevice.odometerKm)} ${unit.distance.display}`
    },
    {
     icon: BatteryAutomotive,
     content: `${Math.round(props.customerDevice.batteryVoltage * 100) / 100} v`
    },
    {
     icon: props.device.offline ? AntennaBarsOff : rssi > 20 ? AntennaBars5 : rssi > 15 ? AntennaBars4 : rssi > 10 ? AntennaBars3 : rssi > 5 ? AntennaBars2 : AntennaBars1,
     content: `${props.device.networkInformation.provider} \n ${props.device.networkInformation.registrationStatus} & ${props.device.networkInformation.systemMode}`
    },
    {
      icon: props.device.immobilization ? KeyOff : Key,
      content: props.device.immobilization ? 'Immobilized' : 'Mobilized'
    },
    {
      icon: props.device.activeKeys?.length || props.device.rentalKeys.nfcKeys?.length ? IdBadge : IdBadgeOff,
      content: props.device.activeKeys?.length || props.device.rentalKeys.nfcKeys?.length ? 'In Rental' : 'No Keys'
    }
  ];

  return (
    <Grid
      templateColumns={{ base: '1fr', large: '1fr 1fr 1fr' }}
      gap="small"
    >
      {elements.map((e, i) => (
        <Card key={i}>
          <Grid templateColumns="0.5fr 2fr">
            <e.icon size={48} color={e.color} />
            <Flex
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              <View style={{marginLeft: '0.5em', whiteSpace: 'pre-wrap'}}>{e.content}</View>
            </Flex>
          </Grid>
        </Card>
      ))}
      <Card>
        <Grid templateColumns="0.5fr 2fr">
          <CustomIcon name="tpms" color={tpmsColor} size={40} />
          <Flex
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <View style={{marginLeft: "0.5em"}}>
              {unit.pressure.convert(props.customerDevice.tirePressure?.frontDriverKpa) || '-'} {unit.pressure.display} &emsp;
              {unit.pressure.convert(props.customerDevice.tirePressure?.frontPassengerKpa) || '-'} {unit.pressure.display}
              <br/>
              {unit.pressure.convert(props.customerDevice.tirePressure?.rearDriverKpa) || '-'} {unit.pressure.display} &emsp;
              {unit.pressure.convert(props.customerDevice.tirePressure?.rearPassengerKpa) || '-'} {unit.pressure.display}
            </View>
          </Flex>
        </Grid>
      </Card>
    </Grid>
  )
}