import { Component } from "react";
import CustomIcon from './CustomIcon';

class HandbrakeIcon extends Component {
  render() {
    const size = this.props.size || 24;
    const color = this.props.handbrake ? 'red' : 'currentColor';

    return (
      <CustomIcon name="handbrake" color={color} size={size} />
    )
  }
}

export default HandbrakeIcon