import { useState } from 'react';
import { Grid, Card, SwitchField, Button, Text } from '@aws-amplify/ui-react';

export default function SetOptions(props) {
  const [cleanSlate, setCleanSlate] = useState(props.cleanSlate);
  const [delayCheck, setDelayCheck] = useState(props.delayCheck);
  const [sendConfig, setSendConfig] = useState(props.sendConfig);

  function handleSubmit() {
    props.onSubmit({
      cleanSlate,
      delayCheck,
      sendConfig
    });
  }

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 1fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <Text>Clean up previous records for the asset</Text>
        <SwitchField
          label="Clean Slate"
          isChecked={cleanSlate}
          onChange={(e) => setCleanSlate(e.target.checked)}
          style={{marginBottom: "0.5em"}}
        />
      </Card>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <Text>Wait before checking syntehsis</Text>
        <SwitchField
          label="Delay Check"
          isChecked={delayCheck}
          onChange={(e) => setDelayCheck(e.target.checked)}
          style={{marginBottom: "0.5em"}}
        />
      </Card>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <Text>Modify the vehicle settings</Text>
        <SwitchField
          label="Send Config"
          isChecked={sendConfig}
          onChange={(e) => setSendConfig(e.target.checked)}
          style={{marginBottom: "0.5em"}}
        />
      </Card>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        <Button
          variation="primary"
          onClick={handleSubmit}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='optionsNext'
        >Next</Button>
        <Button
          variation="secondary"
          style={{marginLeft: "0.5em"}}
          onClick={() => props.onBack()}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='optionsBack'
        >Back</Button>
      </Card>
    </Grid>
  )
}