import { Component } from "react";
import { Circle, DotsCircleHorizontal, CircleDashed, CircleMinus, CircleX, CircleCheck } from 'tabler-icons-react';

class StatusIcon extends Component {
  render() {
    if (this.props.status === 'pending') {
      return (
        <CircleDashed size={48} />
      )
    }
    if (this.props.status === 'skip') {
      return (
        <CircleMinus size={48} color="grey" />
      )
    }
    if (this.props.status === 'waiting') {
      return (
        <DotsCircleHorizontal size={48} />
      )
    }
    if (this.props.status === 'error') {
      return (
        <CircleX size={48}  color="red" />
      )
    }
    if (this.props.status === 'success') {
      return (
        <CircleCheck size={48}  color="green" />
      )
    }
    return (
      <Circle size={48} color="grey" />
    )
  }
}

export default StatusIcon
