import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Grid, Card, Loader, Heading, Text, Button } from '@aws-amplify/ui-react';

export default function CheckAssetInfo(props) {
  const [showLoading, setShowLoading] = useState(true);
  const [discoveryData, setDiscoveryData] = useState();
  const [assetNotElsewhere, setAssetNotElsewhere] = useState();
  const [deviceNotElsewhere, setDeviceNotElsewhere] = useState();

  useEffect(() => {
    if (typeof assetNotElsewhere === 'undefined' || typeof deviceNotElsewhere === 'undefined') return;
    if (assetNotElsewhere && deviceNotElsewhere) {
      props.onNext();
    } else if (props.skipConfirmation) {
      props.onSubmit(discoveryData)
    } else {
      setShowLoading(false);
    }
  }, [assetNotElsewhere, deviceNotElsewhere])

  useEffect(() => {
    API.post('apie9a86f09', `/api/${props.instance}/discover`, { body: {
      assetId: props.assetId,
      deviceId: props.deviceId
    }})
    .then((data) => {
      setDiscoveryData(data);
      setAssetNotElsewhere(Object.keys(data.asset).filter((i) => i !== props.instance).every((i) => data.asset[i] === null));
      setDeviceNotElsewhere(Object.keys(data.device).filter((i) => i !== props.instance).every((i) => data.device[i] === null));
    })
  }, []);

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 2fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        {showLoading && <Loader variation="linear" />}
        {!showLoading && (
          <div>
            <Heading level={4} style={{marginBottom: "0.5em"}}>Warning</Heading>
            <Text variation="error" style={{marginBottom: "0.5em"}}>The {assetNotElsewhere ? '' : 'asset'} {!assetNotElsewhere && !deviceNotElsewhere ? 'and' : ''} {deviceNotElsewhere ? '' : 'device'} {!assetNotElsewhere && !deviceNotElsewhere ? 'are' : 'is'} currently active elsewhere!</Text>
            <Text style={{marginBottom: "0.5em"}}>The system will attempt to relocate it to {props.instance}.</Text>
            <Text style={{marginBottom: "0.5em"}}>By clicking "Confirm" you accept that relocation to {props.instance} is the desired action and will not impact customers.</Text>
            <Button
              variation="primary"
              style={{marginTop: "1em"}}
              onClick={() => props.onSubmit(discoveryData)}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='assetDiscoveryNext'
            >Confirm</Button>
            <Button
              variation="secondary"
              style={{marginLeft: "0.5em"}}
              onClick={() => props.onBack()}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='assetDiscoveryBack'
            >Back</Button>
          </div>
        )}
      </Card>
    </Grid>
  )
}