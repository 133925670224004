import { useEffect } from 'react';
import { API } from 'aws-amplify';
import { Grid, Card, Button, Text, Heading, Alert } from '@aws-amplify/ui-react';
import BooleanIcon from '../icons/BooleanIcon';
import DashboardStatus from '../components/DashboardStatus';
import DetailStatus from '../components/DetailStatus';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export default function CheckAssetInfo(props) {
  useEffect(() => {
    if (!props.parameters) {
      props.pushToast(
        'warning',
        'Missing Parameters',
        'some information will not be available'
      );
    }
  }, []);
  
  async function sendCommand(action) {
    console.log(action)
    if (!props.deviceInfo) return;
    console.log('toast')
    props.pushToast(
      'info',
      `Sending ${capitalize(action)}`,
      'processing...'
    );
    const response = await API.post('apie9a86f09', `/api/${props.instance}/command`, { body: {
      action,
      vehicle: props.deviceInfo.customerDeviceId,
      customer: props.deviceInfo.customerId
    }});
    props.pushToast(
      'success',
      `${capitalize(action)} Sent`,
      `Id: ${response.transaction_id}`
    );
  }

  return (
    <Grid templateColumns={{
      base: "1fr",
      large: "1fr 1fr 1fr 1fr"
    }}>
      <Card
        style={{textAlign: 'center'}}
        columnStart="1"
        columnEnd="-1">
        <DashboardStatus {...props.synthesis} />
        <DetailStatus {...props.synthesis} {...props.parameters} {...props.deviceInfo} />
      </Card>
      <Card variation="elevated">
        <Heading level={6}>Parameters</Heading>
        <Text>GPS Enabled <BooleanIcon value={props.parameters?.gpsEnabled} size={18}/></Text>
        <Text>Immobilizer <BooleanIcon value={props.parameters?.immobilizationEnabled} size={18}/></Text>
        <Text>Deep Sleep - {props.parameters?.deepSleepTimeout}s</Text>
        <Text>Start Timeout - {props.parameters?.unlockedPowerOnTimeout}s</Text>
      </Card>
      <Card variation="elevated">
        <Heading level={6}>Periodic Intervals</Heading>
        <Text>Engine On - {props.parameters?.powerOnStateRefresh}s</Text>
        <Text>Engine Off (Reserved) - {props.parameters?.powerOffReservedStateRefresh}s</Text>
        <Text>Engine Off (Idle) - {props.parameters?.powerOffStateRefresh}s</Text>
        <Text>Charging - {props.parameters?.powerOffChargingStateRefresh}s</Text>
      </Card>
      <Card variation="elevated">
        <Heading level={6}>Alerts</Heading>
        <Text>Alarm <BooleanIcon value={props.parameters?.alarmAlertEnabled} size={18}/></Text>
        <Text>Engine Cycle <BooleanIcon value={props.parameters?.powerStateAlertEnabled} size={18}/></Text>
        <Text>Battery Voltage - {props.parameters?.minAssetBatteryVoltage}v</Text>
        <Text>Speed - {props.parameters?.speedAlertKph}kph</Text>
        <Text>Engine Idle - {props.parameters?.idleAlertTimeout}s</Text>
      </Card>
      <Card variation="elevated">
        <Heading level={6}>OTA Criteria</Heading>
        <Text>No keys <BooleanIcon value={props.parameters?.otaUpdateReadinessCriteria.noKeysSet} size={18}/></Text>
        <Text>Engine Off <BooleanIcon value={props.parameters?.otaUpdateReadinessCriteria.engineOff } size={18}/></Text>
        <Text>Use EDGE <BooleanIcon value={props.parameters?.otaUpdateReadinessCriteria.downloadOnEdge} size={18}/></Text>
        <Text>Battery Voltage - {props.parameters?.otaUpdateReadinessCriteria.batteryVoltage}v</Text>
      </Card>
      <Card variation="elevated">
        <Heading level={6}>Device Info</Heading>
        <Text>evoAll - {props.deviceInfo.evoAll.serialNumber} v{props.deviceInfo.evoAll.firmwareVersion}</Text>
        <Text>evoConnect - {props.deviceInfo.evoConnect.serialNumber} v{props.deviceInfo.evoConnect.firmwareVersion}</Text>
        <Text>antenna - {props.deviceInfo.antenna.serialNumber} v{props.deviceInfo.antenna.firmwareVersion}</Text>
        <Text>IMEI - {props.deviceInfo.modem.imei}</Text>
        <Text>MSIDN - {props.deviceInfo.phoneNumber}</Text>
      </Card>
      <Card variation="elevated">
        <Heading level={6}>Fleet Info</Heading>
        <Text>customerId - {props.deviceInfo.customerId}</Text>
        <Text>fleetId - {props.deviceInfo.fleetId}</Text>
        <Text>instance - {props.instance}</Text>
        <Text>MVNO - {props.deviceInfo.mvno}</Text>
        <Text>{props.deviceInfo.disableNfcRental ? 'NFC Rental Disabled' : ''}</Text>
      </Card>
      <Card variation="elevated">
        <Heading level={6}>GPS</Heading>
        <Text>Satellites - {props.synthesis.device.location.satellites}</Text>
        <Text>Fix Dimensions - {props.synthesis.device.location.gpsFixDimensions}</Text>
        <Text>HDOP - {props.synthesis.device.location.gpsHdop}</Text>
        <Text>Locked - {props.synthesis.device.location.locked ? 'yes' : 'no'}</Text>
        <Text>Timestamp - {props.synthesis.device.location.gpsFixTime}</Text>
      </Card>
      <Card variation="elevated">
        <Heading level={6}>DIAG</Heading>
        <Text>Status <BooleanIcon value={props.synthesis.device.diagnostics.diagnosticOk} size={18}/></Text>
        <Text>Message - {props.synthesis.device.diagnostics.message}</Text>
        <Text>Backup - {Math.round(props.synthesis.device.batteryVoltage * 100) / 100} v</Text>
      </Card>
      <Card
        style={{alignContent: 'center', textAlign: 'center', marginTop: "1em"}}
        columnStart="1"
        columnEnd="-1">
        <Button
              variation="primary"
              onClick={() => { sendCommand('parameters'); sendCommand('version'); }}
              data-amplify-analytics-on='click'
              data-amplify-analytics-name='assetDetailRefresh'
            >Refresh</Button>
        <Button
          variation="secondary"
          style={{marginLeft: "0.5em"}}
          onClick={() => props.onBack()}
          data-amplify-analytics-on='click'
          data-amplify-analytics-name='assetDetailBack'
        >Back</Button>
      </Card>
    </Grid>
  )
}