import { useState } from 'react';
import { API } from 'aws-amplify';
import { Grid, Card, TextField, Button, RadioGroupField, Radio, Loader } from '@aws-amplify/ui-react';

export default function CheckAssetInfo(props) {
  const [showLoading, setShowLoading] = useState(false);
  const [version, setVersion] = useState('');
  const [radioOption, setRadioOption] = useState('');

  async function handleSubmit() {
    setShowLoading(true)
    const payload = {
      moduleType: radioOption,
      firmwareVersion: version,
      retry: 0
    };
    const response = await API.post('apie9a86f09', `/api/${props.instance}/command`, { body: {
      action: 'ota',
      vehicle: props.deviceInfo.customerDeviceId,
      customer: props.instance === 'demo-us1' ? 'nextgen' : props.deviceInfo.customerId,
      payload
    }});
    props.pushToast(
      'success',
      'OTA Started',
      `Id: ${response.transaction_id || response.requestId}`
    );
    props.onNext();
  }

  return (
    <Grid templateColumns={{
      base: "0fr 1fr 0fr",
      large: "1fr 1fr 1fr"
    }}>
      <Card
        columnStart="2"
        columnEnd="3"
      >
        {showLoading && <Loader variation="linear" />}
        {!showLoading && (
        <div>
          <RadioGroupField
            label="Select Unit to Flash"
            value={radioOption}
            onChange={(e) => setRadioOption(e.target.value)}
            style={{ marginBottom: "1em" }}
          >
            <Radio value="evoAll">evoAll - {props.deviceInfo.evoAll.firmwareVersion}</Radio>
            <Radio value="evoConnect">evoConnect - {props.deviceInfo.evoConnect.firmwareVersion}</Radio>
            <Radio value="antenna">antenna - {props.deviceInfo.antenna.firmwareVersion}</Radio>
          </RadioGroupField>
          <TextField
            label="Version"
            placeholder="11.02"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
            style={{ marginBottom: "1em" }}
          />
          <Button
            variation="primary"
            style={{marginTop: "1em"}}
            onClick={handleSubmit}
            data-amplify-analytics-on='click'
            data-amplify-analytics-name='firmwareUpdateNext'
          >Next</Button>
          <Button
            variation="secondary"
            style={{marginLeft: "0.5em"}}
            onClick={() => props.onBack()}
            data-amplify-analytics-on='click'
            data-amplify-analytics-name='firmwareUpdatepBack'
          >Back</Button>
        </div>
        )}
      </Card>
    </Grid>
  )
}