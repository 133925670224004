export default (timestamp: string, detail: boolean = false) => {
  const seconds = Math.floor((new Date().getTime() - new Date(timestamp).getTime()) / 1000);
  let timeAgo = '';

  const minutes = (seconds) => {
    if ((seconds / 60) > 1) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else {
      return `${seconds} second${seconds > 1 ? 's' : ''}`;
    }
  }

  if ((seconds / 86400) > 1) {
    const days = Math.floor(seconds / 86400);
    timeAgo = `${days} day${days > 1 ? 's' : ''}`;
  } else if ((seconds / 3600) > 1) {
    const hours = Math.floor(seconds / 3600)
    timeAgo = `${hours} hour${hours > 1 ? 's' : ''}`;
    if (detail) {
      timeAgo += ' and ' + minutes(seconds - (hours * 3600));
    }
  } else {
    timeAgo = minutes(seconds);
  }

  return timeAgo;
}